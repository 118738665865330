<template>
  <v-dialog
    v-if="parcelShopData"
    :attach="`#${$store.$containerId} .rttApp`"
    v-model="dialog"
    persistent
    :overlay="true"
    content-class="ma-5"
    max-width="500"
  >
    <v-card width="100%" class="card_dialog v-col-12">
      <v-btn
        icon="$close"
        variant="text"
        density="comfortable"
        class="close-button float-right"
        v-on:click="dialogHandler(dialogs.PARCEL_SHOP)"
      />
      <v-card-title class="dialog-title">
        {{ $t('parcelShopDialog.header') }}
      </v-card-title>
      <v-card-text class="px-0 py-2 font-weight-bold">
        {{ parcelShopData.name }}<br />
        {{ address.street }}<br />
        {{ address.city }}
      </v-card-text>
      <table>
        <tr
          class="text-footnote opening-day"
          v-for="openingDay in openingDays"
          :key="openingDay.id"
        >
          <td class="weekday">{{ openingDay.day }}</td>
          <td>{{ openingDay.hours }}</td>
        </tr>
      </table>
      <v-btn
        v-if="!$rttI18n.isGlsItSelected"
        color="primary"
        :block="true"
        class="text-body-1 send-parcel"
        @click="sendClickAnalytics('send_parcel_btn_clicked')"
        v-on:click="navigateToSendParcel"
      >
        {{ $t('parcelShopDialog.sendParcelText') }}
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import { mapNotNull } from '@/util/collections';
import { sendAnalytics } from '@/services/analytics';
import { dialogs } from '@/components/dialogs/dialogs.js';
import { TOGGLE_DIALOG_EVENT } from '@/store/constants.js';

export default {
  name: 'ParcelShopDialog',
  props: {
    open: {
      type: Boolean,
      required: true
    },
    parcelShopData: {
      type: Object,
      default: null
    }
  },
  data() {
    return { dialogs };
  },
  computed: {
    ...mapState(['message']),
    dialog: {
      get() {
        return this.open;
      }
    },
    address() {
      const a = this.parcelShopData.address;
      return {
        street: `${a.street || ''} ${a.houseNumber || ''}`,
        city: `${a.zipCode || ''} ${a.city || ''}`
      };
    },
    openingDays() {
      const openingDaysData = this.parcelShopData.openingDays.reduce(
        (a, v) => ({ ...a, [v.weekday]: v.hours }),
        {}
      );
      return mapNotNull(this.$rttMoment.weekdays, weekday => {
        const openingHours =
          openingDaysData[weekday.shortEngName.toUpperCase()];
        if (!openingHours) return null;
        return {
          day: weekday.localeName,
          hours: openingHours
            .map(hour => `${hour.openingTime} - ${hour.closingTime}`)
            .join(', ')
        };
      });
    }
  },
  methods: {
    ...mapState(['trackingData']),
    dialogHandler(dialog) {
      this.$mitt.emit(TOGGLE_DIALOG_EVENT, dialog);
    },
    navigateToSendParcel() {
      const url = this.$rttI18n.tenantSpecificWithFallback(
        'parcelShopDialog.glsUrl'
      );
      window.open(url, '_blank').focus();
    },
    sendClickAnalytics(eventName) {
      sendAnalytics(this.trackingData(), 'map', eventName, {
        parcelShopId: this.parcelShopData.parcelShopId,
        distance: this.parcelShopData.distance
      });
    }
  }
};
</script>

<style scoped>
.opening-day {
  line-height: 1.5em;
}
.weekday {
  padding-right: 1em;
}
.send-parcel {
  margin-top: 1em;
}
</style>
