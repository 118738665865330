<template>
  <v-dialog
    :attach="`#${$store.$containerId} .rttApp`"
    v-model="dialog"
    persistent
    :overlay="true"
    content-class="ma-5 overflow-visible"
    width="100%"
    scrollable
  >
    <v-card width="100%" class="card_dialog v-col-12">
      <v-btn
        icon="$close"
        variant="text"
        density="comfortable"
        class="close-button float-right"
        v-on:click="dialogHandler(dialogs.CUSTOMIZE)"
      />
      <v-card-title class="dialog-title">
        {{ $t('customization.header') }}
      </v-card-title>
      <v-row class="v-col-12 text-center row-auto-cols-280 pa-0 ma-0">
        <v-card
          v-if="
            supportedCustomization.includes(DEPOSIT_PERMISSION) &&
            !configGetter($store.$i18n, themeId).disableDepositPermission
          "
          elevation="0"
          class="card bg-info pa-4"
          v-on:click="
            () => {
              dialogHandler(dialogs.CUSTOMIZE);
              dialogHandler(dialogs.DROP_OFF);
            }
          "
        >
          <img
            :src="publicPath(`/themes/${themeId}/actions/icon_drop_off.svg`)"
            alt=""
            width="35"
            height="35"
          />
          <p class="text-center text-info">
            {{ $t('customization.dropOffPermission.header') }}
          </p>
        </v-card>
        <v-card
          v-if="
            supportedCustomization.includes(DELIVER_TO_NEIGHBOUR) &&
            !configGetter($rttI18n.tenantId, themeId).disableDeliverToNeighbour
          "
          elevation="0"
          class="card bg-info pa-4"
          v-on:click="
            () => {
              dialogHandler(dialogs.CUSTOMIZE);
              dialogHandler(dialogs.NEIGHBOUR);
            }
          "
        >
          <img
            :src="
              publicPath(
                `/themes/${themeId}/actions/icon_deliver_neighbour.svg`
              )
            "
            alt=""
            width="35"
            height="35"
          />
          <p class="text-center text-info">
            {{ $t('customization.neighbour.header') }}
          </p>
        </v-card>
        <v-card
          elevation="0"
          class="card bg-info pa-4"
          v-on:click="
            () => {
              dialogHandler(dialogs.CUSTOMIZE);
              dialogHandler(dialogs.DELIVERY_NOTE);
            }
          "
        >
          <img
            :src="publicPath(`/themes/${themeId}/actions/delivery_note.svg`)"
            alt=""
            width="35"
            height="35"
          />
          <p class="text-center text-info">
            {{ $t('customization.deliveryHints.header') }}
          </p>
        </v-card>
        <v-card
          v-if="
            smsNotification &&
            smsNotification.type === NOTIFICATION_TYPE_ELIGIBLE &&
            getTrackingState !== TRACKING_DELIVERY_STATE_LAST
          "
          elevation="0"
          class="card bg-info pa-4"
          v-on:click="
            () => {
              dialogHandler(dialogs.CUSTOMIZE);
              dialogHandler(dialogs.NOTIFICATION_SUBSCRIPTION);
            }
          "
        >
          <img
            :src="
              publicPath(`/themes/${themeId}/actions/icon_smartphone_text.svg`)
            "
            alt=""
            width="35"
            height="35"
          />
          <p class="text-center text-info">
            {{ $t('customization.notificationSubscription.header') }}
          </p>
        </v-card>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  DELIVER_TO_NEIGHBOUR,
  DEPOSIT_PERMISSION,
  NOTIFICATION_TYPE_ELIGIBLE,
  TOGGLE_DIALOG_EVENT,
  TRACKING_DELIVERY_STATE_LAST
} from '@/store/constants';
import { mapGetters, mapState } from 'vuex';
import { configGetter } from '@/config/configGetter';
import publicPath from '@/util/publicPath';
import { dialogs } from '@/components/dialogs/dialogs.js';

export default {
  name: 'CustomizeDialog',
  methods: {
    publicPath,
    dialogHandler(dialog) {
      this.$mitt.emit(TOGGLE_DIALOG_EVENT, dialog);
    }
  },
  props: {
    open: {
      type: Boolean,
      required: true
    },
    smsNotification: {
      type: Object,
      required: false
    },
    pushNotification: {
      type: Object,
      required: false
    },
    supportedCustomization: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      DELIVER_TO_NEIGHBOUR,
      DEPOSIT_PERMISSION,
      TRACKING_DELIVERY_STATE_LAST,
      NOTIFICATION_TYPE_ELIGIBLE,
      configGetter,
      dialogs
    };
  },
  computed: {
    ...mapState(['themeId']),
    ...mapGetters(['getTrackingState']),
    dialog: {
      get() {
        return this.open;
      }
    }
  }
};
</script>

<style scoped>
.card img {
  margin: 20px;
  width: 35px;
  height: 35px;
}
.card.bg-info {
  border: 1px solid rgb(var(--v-theme-info));
}
.card.bg-info:hover {
  background-color: white !important;
}
.card.bg-info:not(:hover) > * {
  filter: invert(1) saturate(0) brightness(100); /* https://codepen.io/sosuke/pen/Pjoqqp */
}
</style>
