<template>
  <v-dialog
    :attach="`#${$store.$containerId} .rttApp`"
    v-model="dialog"
    persistent
    :overlay="true"
    content-class="ma-5"
    max-width="500"
  >
    <v-card width="100%" class="card_dialog v-col-12">
      <v-btn
        icon="$close"
        variant="text"
        density="comfortable"
        class="close-button float-right"
        v-on:click="dialogHandler(dialogs.TRUST_PILOT_CONSENT_DIALOG)"
      />
      <v-card-title id="heading" class="dialog-title"
        ><span class="star">★</span>Trustpilot
      </v-card-title>
      <img
        class="d-block"
        alt="TrustPilot Rating"
        src="/parcel_delivery.webp"
        v-on:click="gotoTrustPilotRatingPage()"
      />
      <v-card-text class="px-0 py-2">{{
        this.$t('feedback.trustPilot.consent')
      }}</v-card-text>
      <v-btn
        :block="true"
        type="submit"
        v-on:click="handleConsent"
        class="proceed bg-info"
        color="info"
        >{{ $t('generic.proceed') }}</v-btn
      >
    </v-card>
  </v-dialog>
</template>

<script>
import { TOGGLE_DIALOG_EVENT } from '@/store/constants.js';
import { sendAnalytics } from '@/services/analytics';
import { dialogs } from '@/components/dialogs/dialogs.js';
import { mapState } from 'vuex';

export default {
  name: 'TrustPilotConsentDialog',
  props: {
    open: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return { dialogs };
  },
  computed: {
    ...mapState(['trackingData']),
    dialog: {
      get() {
        return this.open;
      }
    }
  },
  methods: {
    dialogHandler(dialog) {
      this.$mitt.emit(TOGGLE_DIALOG_EVENT, dialog);
    },
    handleConsent() {
      this.openTrustPilot();
      this.dialogHandler(dialogs.TRUST_PILOT_CONSENT_DIALOG);
    },
    openTrustPilot() {
      sendAnalytics(this.trackingData, 'consent', 'trustpilot', {
        text: this.$t('feedback.trustPilot.consent'),
        name: this.trackingData.toAddress?.name,
        fromFooter: this.inFooter
      });
      window.open(this.trackingData.trustpilotInvitationLink, '_blank');
    }
  }
};
</script>

<style scoped>
button.proceed {
  margin-top: 2em;
  margin-bottom: 1em;
}
span.star {
  color: #64d694;
  margin-right: 0.5em;
}
#heading {
  font-size: 1.5em;
}
</style>
